<template>
    <div>
        <footer>
            <div class="bg">
                <svg xmlns="http://www.w3.org/2000/svg" width="1620.001" height="540" viewBox="0 0 1620.001 540">
                    <path id="Path_28759" data-name="Path 28759" d="M1078,560.788l-268-269-268,269H0l540-540h540l540,540Z" transform="translate(0 -20.788)" fill="#13213c" opacity="0.411" style="mix-blend-mode: multiply;isolation: isolate"/>
                </svg>
            </div>
            <div class="container contentbox">
                <router-link :to="{name:'home', params: { locale: locale }}" class="footer-logo" :title="brand_slug">
                    <img :src="require(`../../img/${brand_slug}/logo-white.svg`)" />
                </router-link>
                <div class="items">
                    <ul>
                        <li>
                            <h3>{{ $t('Certificates') }}</h3>
                            <div class="txtbox">
                                <div class="txt">
                                    {{ $t('LAUR MADE IN KOSZALIN 2017 Deloitte Technology Fast 50 Central Europe 2017 Forbes Diamond 2023 Business Pearl 2023 Quality Guarantee') }}
                                </div>
                                <div class="subtitle">{{ $t('Quality guarantee') }}</div>
                            </div>
                        </li>
                        <li>
                            <h3>{{ $t('Information') }}</h3>
                            <div class="txtbox">
                                <ul>
                                    <li>
                                        <a href="">{{ $t('FAQ') }}</a>
                                    </li>
                                    <li>
                                        <a href="">{{ $t('Complaints') }}</a>
                                    </li>
                                    <li>
                                        <a href="">{{ $t('Terms and Conditions') }}</a>
                                    </li>
                                    <li>
                                        <a href="">{{ $t('Privacy Policy') }}</a>
                                    </li>
                                    <li>
                                        <a href="">{{ $t('BLOG') }}</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3>{{ $t('Company') }}</h3>
                            <div class="txtbox">
                                <ul>
                                    <li>
                                        <a href="">{{ $t('About us') }}</a>
                                    </li>
                                    <li>
                                        <a href="">{{ $t('Industries') }}</a>
                                    </li>
                                    <li>
                                        <a href="">{{ $t('Services') }}</a>
                                    </li>
                                    <li>
                                        <a href="">{{ $t('Languages') }}</a>
                                    </li>
                                    <li>
                                        <a href="">{{ $t('Case Study') }}</a>
                                    </li>
                                    <li>
                                        <a href="">{{ $t('Work') }}</a>
                                    </li>
                                    <li>
                                        <a href="" class="login">{{ $t('Log in') }}</a>
                                    </li>
                                </ul>
                                <div class="smbox">
                                    <a href="#" class="sm-fb"></a>
                                    <a href="#" class="sm-in"></a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <h3>{{ $t('Dane') }}</h3>
                            <div class="txtbox">
                                <div class="txt">
                                    dogadamycie.pl Sp. z o.o.
                                    <br /><br />
                                    ul. Zwycięstwa 115 75-601 Koszalin, Polska
                                    <br /><br />
                                    NIP PL 6692518347<br />
                                    D-U-N-S Number: 426900047
                                    <br /><br />
                                    <div class="data-contact">
                                        <a href="">+48 22 460 29 90</a>
                                        <a href="mailto:info@dogadamycie.pl">info@dogadamycie.pl</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
import Locale from "./Locale";
import {
    BDropdownItem, BNavItemDropdown, BIconArrowClockwise,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
} from "bootstrap-vue";
import i18n from "../../../common/js/i18n";
import BrandConfig from "../BrandConfig";
export default {
    name: 'Footer',
    components: {
        Locale, BDropdownItem, BNavItemDropdown, BIconArrowClockwise,
        BNavbar,
        BNavbarBrand,
        BNavbarToggle,
        BCollapse,
    },
    data() {
        return {
            locale: i18n.locale,
            brand_slug: BrandConfig.brandName
        }
    },
    created() {

    },
    mounted() {

    },
    computed: {

    },
    methods: {

    }
}
</script>

